export default class PageReloader {

  constructor() {
    this.pageLoadedTs = this.getNowTimestamp();
  }

  reloadOutdatedPage(pageHidden) {
    if (pageHidden) {
      const nowTs = this.getNowTimestamp();
      const TWO_DAYS = 1000 * 3600 * 24 * 2;
      if (nowTs - this.pageLoadedTs > TWO_DAYS) {
        location.reload(true);
      }
    }
  }

  getNowTimestamp() {
    return new Date().getTime();
  }
  
}
