import confetti from 'canvas-confetti';

let end;
let myColors;

export function launch(colors) {
  myColors = colors;
  end = Date.now() + 5 * 1000;
  boom();
}

function boom() {
  const interval = setInterval(function() {
    if (Date.now() > end) {
      return clearInterval(interval);
    }
    confetti({
      startVelocity: 30,
      particleCount: 100,
      spread: 360,
      ticks: 60,
      origin: { x: Math.random(), y: Math.random() - 0.2 },
      colors: myColors
    });
  }, 200);
}
