import './images/alarm_clock.png';
import './images/atom.png';
import './images/autumn-leaves.jpg';
import './images/award.png';
import './images/beach-ball.jpg';
import './images/bicycle.png';
import './images/birth-of-the-universe.jpg';
import './images/blue-frost-pattern.jpg';
import './images/blue-glowing-vortex.jpg';
import './images/blue-swirl.jpg';
import './images/book.png';
import './images/brush_palette.png';
import './images/brushed-metal.png';
import './images/caffe-latte.jpg';
import './images/calculator.png';
import './images/cash.png';
import './images/cat-portrait.jpg';
import './images/chocolate-chip-cookie.jpg';
import './images/christmas-wrapping-paper.jpg';
import './images/composition_book.png';
import './images/disco-ball.jpg';
import './images/dna.png';
import './images/dog-portrait.jpg';
import './images/dollars.png';
import './images/donut.jpg';
import './images/floppy_disk.png';
import './images/fortnite-llama.png';
import './images/game_controller.png';
import './images/gdg_logo1.png';
import './images/gdg_logo2.png';
import './images/gear_head.png';
import './images/gerbera-flower.jpg';
import './images/globe.jpg';
import './images/globe.png';
import './images/google_cloud_logo.png';
import './images/google_logo.png';
import './images/graduation_cap.png';
import './images/green-grass.jpg';
import './images/green-leaves.jpg';
import './images/halloween.jpg';
import './images/hat-with-names.png';
import './images/heart.png';
import './images/html.png';
import './images/lightbulb_head.png';
import './images/microscope.png';
import './images/molecules.png';
import './images/moon.jpg';
import './images/night-sky.jpg';
import './images/none.png';
import './images/orange-slice.jpg';
import './images/palm-trees.jpg';
import './images/pencils.png';
import './images/present.png';
import './images/rainbow.jpg';
import './images/rocket.png';
import './images/rulers.png';
import './images/soccer-ball.jpg';
import './images/spiral-nebula.jpg';
import './images/spiral-tie-dye.jpg';
import './images/spy.png';
import './images/star-trails.jpg';
import './images/trophy.png';
import './images/water-in-pool.jpg';
import './images/water-swirl.jpg';
import './images/whirlpool.jpg';
import './images/wood.jpg';
import './images/year-of-the-tiger.jpg';

export default class ImageCache {

  constructor() {
    this.images = new Object();
    this.emptyImage = new Image();
    // This dataUrl is a 1x1 transparent image.
    this.emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }

  addImage(imageName) {
    this.images[imageName] = new Image();
    this.images[imageName].src = imageName;
  }

  getImage(imageName) {
    if (!imageName) return;
    if (imageName in this.images) {
      // Do nothing.
    }
    else {
      if (imageName) this.addImage(imageName);
    }
    if (this.images[imageName] && this.images[imageName].naturalHeight > 0) {
      return this.images[imageName];
    }
    else {
      // If the image is broken, return an empty image instead,
      // to prevent Javascript errors when attempting to draw it.
      return this.emptyImage;
    }
  };

}
