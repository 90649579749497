export async function getTitle(sheetId) {
  const response = await gapi.client.sheets.spreadsheets.get({
    spreadsheetId: sheetId
  });
  return response.result.properties.title;
}

export async function getTabNames(sheetId) {
  const response = await gapi.client.sheets.spreadsheets.get({
    spreadsheetId: sheetId
  });
  return response.result.sheets.map(sheet => sheet.properties.title);
}

export async function getColumns(sheetId, tabName) {
  const response = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: sheetId,
    range: tabName
  });
  const columns = [];
  const range = response.result;
  if (range.values && range.values.length > 0) {
    const row = skipEmptyRows(range.values)[0];
    for (let i=0; i<row.length && i<27; i++) {
      columns.push({id: String.fromCharCode(i+65), name: row[i]});
    }
  }
  return columns;
}

export async function getEntries(sheetId, tabName, columnId, skipFirstRow) {
  const response = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: sheetId,
    range: tabName + '!' + columnId + ':' + columnId,
  });
  const entries = [];
  if (response.result.values && response.result.values.length>0) {
    const rows = skipEmptyRows(response.result.values);
    for (let i=0; i<rows.length; i++) {
      if (i == 0 && skipFirstRow) {
        // Skip the first row if requested.
      }
      else {
        const row = rows[i];
        if (typeof row[0] != 'undefined') {
          entries.push(row[0]);
        }
      }
    }
  }
  return entries;
}

function skipEmptyRows(sheetRows) {
  return sheetRows.filter(row => row.length>0)
}
