exports.list = [
  {fileName: "/images/alarm_clock.png", title: "Alarm clock"},
  {fileName: "/images/atom.png", title: "Atom"},
  {fileName: "/images/award.png", title: "Award"},
  {fileName: "/images/bicycle.png", title: "Bicycle"},
  {fileName: "/images/book.png", title: "Book"},
  {fileName: "/images/brush_palette.png", title: "Brush and palette"},
  {fileName: "/images/calculator.png", title: "Calculator"},
  {fileName: "/images/cash.png", title: "Cash"},
  {fileName: "/images/composition_book.png", title: "Composition book"},
  {fileName: "/images/dna.png", title: "DNA"},
  {fileName: "/images/dollars.png", title: "Dollars"},
  {fileName: "/images/floppy_disk.png", title: "Floppy disk"},
  {fileName: "/images/fortnite-llama.png", title: "Fortnite Llama (credit: Marco Verch, Flickr)"},
  {fileName: "/images/game_controller.png", title: "Game controller"},
  {fileName: "/images/gear_head.png", title: "Gear head"},
  {fileName: "/images/globe.png", title: "Globe"},
  {fileName: "/images/graduation_cap.png", title: "Graduation cap"},
  {fileName: "/images/heart.png", title: "Heart"},
  {fileName: "/images/html.png", title: "HTML"},
  {fileName: "/images/lightbulb_head.png", title: "Lightbulb head"},
  {fileName: "/images/microscope.png", title: "Microscope"},
  {fileName: "/images/molecules.png", title: "Molecule"},
  {fileName: "/images/pencils.png", title: "Pencils"},
  {fileName: "/images/present.png", title: "Present"},
  {fileName: "/images/rocket.png", title: "Rocket"},
  {fileName: "/images/rulers.png", title: "Rulers"},
  {fileName: "/images/spy.png", title: "Spy"},
  {fileName: "/images/trophy.png", title: "Trophy"},
]
