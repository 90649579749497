<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          This year's numbers
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <yearCounter title="Wheel spins" :increment="spinsPerSecond" :fps="0.333333"/>
        <br>
        <yearCounter title="Hours of spinning" :increment="hoursSpunPerSecond" :fps="hoursSpunPerSecond"/>
      </div>
    </div>
  </div>
</template>

<script>

  import yearCounter from './yearCounter.vue';
  import '../images/favicon-32x32.png';
  import * as ServerFunctions from '../ServerFunctions.js';

  export default {
    components: { yearCounter },
    data() {
      return {
        spinsPerSecond: 22.196,
        hoursSpunPerSecond: 0.061655
      }
    },
    async mounted() {
      const spinStats = await ServerFunctions.getSpinStats();
      if (spinStats.spinsPerSecond) this.spinsPerSecond = spinStats.spinsPerSecond;
      if (spinStats.hoursSpunPerSecond) this.hoursSpunPerSecond = spinStats.hoursSpunPerSecond;
    }
  }

</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
