// ----------------------------------------------------------------------
// Call setTimestamp() on this object every animation frame. You can then
// call shouldTick() to learn if you should advance the model by 1/60th
// of a seccond. Call shouldTick() repeatedly (and advance the model each
// time) until it returns false.
// ----------------------------------------------------------------------

export default class Ticker {

  constructor() {
    this.lastFrameTimeMs = 0;
    this.delta = 0;
    this.timestep = 1000 / 60;
  }

  setTimestamp(timestamp) {
    if (this.lastFrameTimeMs == 0) {
      // This is the first frame. We need to run tick once to init.
      this.delta = this.timestep;
    }
    else {
      this.delta += timestamp - this.lastFrameTimeMs;
    }
    this.lastFrameTimeMs = timestamp;
  }

  shouldTick() {
    const retVal = (this.delta >= this.timestep);
    if (retVal) this.delta -= this.timestep;
    return retVal;
  }

}
