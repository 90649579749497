import Vue from 'vue';
import VueRouter from 'vue-router';
import wheelPage from './pages/wheelPage.vue';
const exportPage = () => import(/* webpackChunkName: "exportPage" */'./pages/exportPage.vue');
const faqPage = () => import(/* webpackChunkName: "faqPage" */'./pages/faqPage.vue');
const privacyPolicyPage = () => import(/* webpackChunkName: "privacyPolicyPage" */'./pages/privacyPolicyPage.vue');
const translationsPage = () => import(/* webpackChunkName: "translationsPage" */'./pages/translationsPage.vue');
const classroomPage = () => import(/* webpackChunkName: "classroomPage" */'./pages/classroomPage.vue');
const tutorialsPage = () => import(/* webpackChunkName: "tutorialsPage" */'./pages/tutorialsPage.vue');
const notFoundPage = () => import(/* webpackChunkName: "notFoundPage" */'./pages/notFoundPage.vue');
import * as Firebase from './Firebase.js';
import * as ServerFunctions from './ServerFunctions.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/faq.html',
      component: faqPage
    },
    {
      path: '/faq/:section?',
      component: faqPage
    },
    {
      path: '/:lang([a-z][a-z])?/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)?',
      component: wheelPage
    },
    {
      path: '/:lang([a-z][a-z]-[A-Z][A-Z])?/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)?',
      component: wheelPage
    },
    {
      // Three-letter lang codes don't work with "?" (optional marker).
      path: '/:lang([a-z][a-z][a-z])',
      component: wheelPage
    },
    {
      // Three-letter lang codes don't work with "?" (optional marker).
      path: '/:lang([a-z][a-z][a-z])/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)?',
      component: wheelPage
    },
    {
      path: '/view/:lang([a-z][a-z])?/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)',
      component: wheelPage
    },
    {
      path: '/view/:lang([a-z][a-z]-[A-Z][A-Z])?/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)',
      component: wheelPage
    },
    {
      // Three-letter lang codes don't work with "?" (optional marker).
      path: '/view/:lang([a-z][a-z][a-z])/:sharedWheelPath(\\w\\w\\w\-\\w\\w\\w)',
      component: wheelPage
    },
    {
      path: '/privacy-policy(.html)?',
      component: privacyPolicyPage
    },
    {
      path: '/export',
      component: exportPage
    },
    {
      path: '/translate(.html)?',
      component: translationsPage
    },
    {
      path: '/how-to-use-in-the-classroom',
      component: classroomPage
    },
    {
      path: '/user-reviews-and-tutorials',
      component: tutorialsPage
    },
    {
      path: '/robots.txt',
      component: wheelPage
    },
    {
      path: '/*',
      component: notFoundPage
    }
  ]
});

router.afterEach((to, from) => {
  ga('set', 'page', to.path);
  ga('send', 'pageview');
});

export default router;
