<template>
  <div v-if="showAd">
    <div class="enthusiast-adhesion">
      <div class="enthusiast-close-button" @click="hideAd()">X</div>
      <div id="div-gpt-ad-sticky-bottom"></div>
    </div>
  </div>
</template>

<script>
  import * as Util from './Util.js';
  import * as AdUtil from './AdUtil.js';

  export default {
    data() {
      return {
        showAd: false
      };
    },
    mounted() {
      const self = this;
      setTimeout(() => {
        self.showAd = true;
        AdUtil.loadScript();
      }, 7000);
    },
    methods: {
      hideAd() {
        Util.trackEvent('Wheel', 'HideMobileAd', '');
        this.showAd = false;
      }
    }
  }
</script>

<style>
  .enthusiast-adhesion {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    text-align: center;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    border-top:solid 1px #ccc;
    background:#ffffffc2;
  }
  .enthusiast-adhesion .enthusiast-close-button {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 1.5px;
    cursor: pointer;
    background: #292A2D;
    color: #fff;
    border-radius: 18px;
    z-index: 5000 !important;
    position: absolute;
    right: 10px;
    bottom: 100%;
    width: 22px;
    height: 22px;
    margin-bottom: 10px;
  }
</style>
