var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card can-go-dark" }, [
    _c(
      "div",
      {
        staticClass: "card-content",
        staticStyle: { padding: "12px 12px 0px 12px" }
      },
      [
        _c(
          "div",
          {
            staticClass: "columns is-mobile",
            staticStyle: { "margin-bottom": "0" }
          },
          [
            _c(
              "div",
              {
                staticClass: "column is-narrow",
                style: _vm.entry.enabled ? "" : "opacity:0.4"
              },
              [
                _c("div", { staticClass: "v-centered" }, [
                  _vm.i > 0
                    ? _c("i", {
                        staticClass: "fas fa-arrow-up clickable-icon",
                        on: {
                          click: function($event) {
                            return _vm.moveEntry(_vm.i, -1)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.i > 0 ? _c("br") : _vm._e(),
                  _vm._v(" "),
                  _vm.i < _vm.entries.length - 1
                    ? _c("i", {
                        staticClass: "fas fa-arrow-down clickable-icon",
                        on: {
                          click: function($event) {
                            return _vm.moveEntry(_vm.i, 1)
                          }
                        }
                      })
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "column",
                staticStyle: { padding: "0" },
                style: _vm.entry.enabled ? "" : "opacity:0.4"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "columns is-mobile",
                    staticStyle: {
                      "padding-left": "0",
                      "padding-right": "6px",
                      margin: "12px 0 0 0"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        staticStyle: {
                          padding: "0 8px 0 8px",
                          "margin-bottom": "12px"
                        }
                      },
                      [
                        _c("b-input", {
                          ref: "textbox",
                          attrs: { disabled: _vm.wheelIsBusy },
                          on: {
                            input: function($event) {
                              return _vm.updateEntryText(_vm.i, _vm.entry.text)
                            }
                          },
                          model: {
                            value: _vm.entry.text,
                            callback: function($$v) {
                              _vm.$set(_vm.entry, "text", $$v)
                            },
                            expression: "entry.text"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.wheelConfig.type == "color"
                      ? _c(
                          "div",
                          {
                            staticClass: "column is-narrow",
                            staticStyle: { padding: "2px 8px 0 8px" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.entry.color,
                                  expression: "entry.color"
                                }
                              ],
                              attrs: {
                                type: "color",
                                disabled: _vm.wheelIsBusy
                              },
                              domProps: { value: _vm.entry.color },
                              on: {
                                change: function($event) {
                                  return _vm.updateEntryColor(
                                    _vm.i,
                                    _vm.entry.color
                                  )
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.entry,
                                    "color",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.entry.image
                  ? _c("div", { staticStyle: { padding: "0 8px 0 8px" } }, [
                      _c("img", {
                        staticStyle: {
                          "border-radius": "4px",
                          "max-height": "40px"
                        },
                        attrs: {
                          src: _vm.entry.image,
                          alt: _vm.$t("advancededitor.Image attached")
                        }
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fas fa-times clickable-icon",
                        staticStyle: {
                          "padding-top": "2px",
                          "vertical-align": "top"
                        },
                        on: {
                          click: function($event) {
                            return _vm.removeImageFromEntry(_vm.i)
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("transition", { attrs: { name: "slide" } }, [
                  _vm.expanded
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0 0 8px 8px",
                            width: "170px"
                          }
                        },
                        [
                          _c(
                            "b-select",
                            {
                              attrs: {
                                "icon-pack": "fas",
                                icon: "volume-up",
                                size: "is-small",
                                disabled: _vm.wheelIsBusy
                              },
                              model: {
                                value: _vm.sound,
                                callback: function($$v) {
                                  _vm.sound = $$v
                                },
                                expression: "sound"
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "advancededitor.Inherit from wheel"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.afterSpinSounds, function(sound) {
                                return _c(
                                  "option",
                                  {
                                    key: sound.value,
                                    domProps: { value: sound.value }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t(sound.name)) +
                                        "\n              "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.wheelConfig.displayWinnerDialog
                            ? _c("b-input", {
                                staticStyle: { "margin-top": "8px" },
                                attrs: {
                                  placeholder: _vm.$t(
                                    "advancededitor.Popup message"
                                  ),
                                  size: "is-small",
                                  disabled: _vm.wheelIsBusy
                                },
                                model: {
                                  value: _vm.message,
                                  callback: function($$v) {
                                    _vm.message = $$v
                                  },
                                  expression: "message"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "columns is-mobile is-vcentered" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "column is-9",
                                  staticStyle: { "margin-top": "8px" }
                                },
                                [
                                  _c(
                                    "b-field",
                                    [
                                      _c("b-numberinput", {
                                        attrs: {
                                          size: "is-small",
                                          "icon-pack": "fas",
                                          icon: "balance-scale-left",
                                          disabled: _vm.wheelIsBusy,
                                          min: "1",
                                          max: "999",
                                          "controls-rounded": "",
                                          "controls-position": "compact"
                                        },
                                        model: {
                                          value: _vm.weight,
                                          callback: function($$v) {
                                            _vm.weight = $$v
                                          },
                                          expression: "weight"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "column is-narrow",
                                  staticStyle: {
                                    "margin-top": "8px",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.odds) +
                                      "%\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "column is-narrow",
                staticStyle: { padding: "0", margin: "12px 0 0 0" },
                style: _vm.entry.enabled ? "" : "opacity:0.4"
              },
              [
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t("advancededitor.Duplicate"),
                      type: "is-light",
                      position: "is-left"
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "far fa-clone clickable-icon",
                      on: {
                        click: function($event) {
                          return _vm.duplicateEntry(_vm.i)
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: _vm.$t("textboxbuttons.Add image"),
                      type: "is-light",
                      position: "is-left"
                    }
                  },
                  [
                    _c(
                      "b-upload",
                      {
                        attrs: { accept: "image/*", disabled: _vm.wheelIsBusy },
                        model: {
                          value: _vm.uploadedImage,
                          callback: function($$v) {
                            _vm.uploadedImage = $$v
                          },
                          expression: "uploadedImage"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-image clickable-icon",
                          on: {
                            click: function($event) {
                              return _vm.attachImageToEntry(_vm.i)
                            }
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column is-narrow" },
              [
                _c("i", {
                  staticClass: "fas fa-times clickable-icon",
                  staticStyle: { "font-size": "125%", "padding-top": "2px" },
                  style: _vm.entry.enabled ? "" : "opacity:0.4",
                  on: {
                    click: function($event) {
                      return _vm.removeEntry(_vm.i)
                    }
                  }
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      type: "is-light",
                      position: "is-left",
                      label: _vm.entry.enabled
                        ? _vm.$t("advancededitor.Shown on the wheel")
                        : _vm.$t("advancededitor.Not shown on the wheel")
                    }
                  },
                  [
                    _c("b-checkbox", {
                      staticStyle: { "margin-right": "-10px" },
                      attrs: { size: "is-small", disabled: _vm.wheelIsBusy },
                      on: {
                        input: function($event) {
                          return _vm.toggleEntryEnabled()
                        }
                      },
                      model: {
                        value: _vm.entry.enabled,
                        callback: function($$v) {
                          _vm.$set(_vm.entry, "enabled", $$v)
                        },
                        expression: "entry.enabled"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "has-text-centered",
            style:
              (_vm.entry.enabled ? "" : "opacity:0.4;") +
              (_vm.expanded ? "margin-top:-6px" : "margin-top:-20px")
          },
          [
            _c("i", {
              class:
                "fas clickable-icon stretched fa-angle-" +
                (_vm.expanded ? "up" : "down"),
              on: {
                click: function($event) {
                  return _vm.toggleExpanded()
                }
              }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }