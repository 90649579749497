var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-field", { attrs: { grouped: "", "group-multiline": "" } }, [
    _c(
      "p",
      { staticClass: "control" },
      [
        _c(
          "b-button",
          {
            attrs: {
              size: "is-small",
              type: "is-light",
              disabled: _vm.buttonsDisabled
            },
            on: { click: _vm.shuffle }
          },
          [
            _c("i", { staticClass: "fas fa-random" }),
            _vm._v(" " + _vm._s(_vm.$t("textboxbuttons.Shuffle")) + "\n    ")
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "control" },
      [
        _c(
          "b-button",
          {
            attrs: {
              size: "is-small",
              type: "is-light",
              disabled: _vm.buttonsDisabled
            },
            on: { click: _vm.sort }
          },
          [
            _c("i", { staticClass: "fas fa-sort-alpha-up" }),
            _vm._v(" " + _vm._s(_vm.$t("textboxbuttons.Sort")) + "\n    ")
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "control", staticStyle: { "margin-top": "-1px" } },
      [
        _c(
          "b-upload",
          {
            attrs: {
              accept: "image/*",
              multiple: "",
              disabled: _vm.buttonsDisabled
            },
            model: {
              value: _vm.uploadedImage,
              callback: function($$v) {
                _vm.uploadedImage = $$v
              },
              expression: "uploadedImage"
            }
          },
          [
            _c(
              "a",
              {
                staticClass: "button is-small is-light",
                attrs: { disabled: _vm.buttonsDisabled }
              },
              [
                _c("i", { staticClass: "far fa-image" }),
                _vm._v(
                  " " + _vm._s(_vm.$t("textboxbuttons.Add image")) + "\n      "
                )
              ]
            )
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "control" },
      [
        _vm.displayAdvancedCheckbox
          ? _c(
              "b-checkbox",
              {
                key: _vm.updateAdvancedCheckbox,
                attrs: { size: "is-small", disabled: _vm.buttonsDisabled },
                model: {
                  value: _vm.wheelIsAdvanced,
                  callback: function($$v) {
                    _vm.wheelIsAdvanced = $$v
                  },
                  expression: "wheelIsAdvanced"
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("textboxbuttons.Advanced")) +
                    "\n    "
                )
              ]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }