var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "columns is-vcentered is-mobile" }, [
      _c("div", { staticClass: "column is-narrow" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("optionsdialog.Image in the center of the wheel")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-dropdown",
            {
              ref: "dropDown",
              attrs: {
                "append-to-body": "",
                "aria-role": "menu",
                scrollable: "",
                "max-height": "400",
                "trap-focus": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "trigger",
                  fn: function(ref) {
                    var active = ref.active
                    return [
                      _c(
                        "b-button",
                        {
                          staticStyle: { height: "50px" },
                          attrs: {
                            "icon-right": active ? "caret-up" : "caret-down"
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "preview-image",
                            attrs: { src: _vm.imageSrc }
                          })
                        ]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dropdownItem,
                callback: function($$v) {
                  _vm.dropdownItem = $$v
                },
                expression: "dropdownItem"
              }
            },
            [
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.imageSrc.includes("none"),
                      expression: "!imageSrc.includes('none')"
                    }
                  ],
                  key: "none",
                  attrs: { custom: "", value: "none" }
                },
                [
                  _c("b-button", { on: { click: _vm.removeImage } }, [
                    _c("i", { staticClass: "fas fa-ban" }),
                    _vm._v(
                      "\n             \n            " +
                        _vm._s(_vm.$t("optionsdialog.Remove image")) +
                        "\n          "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("b-dropdown-item", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.imageSrc.includes("none"),
                    expression: "!imageSrc.includes('none')"
                  }
                ],
                attrs: { separator: "" }
              }),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { key: "upload", attrs: { custom: "", value: "upload" } },
                [
                  _c(
                    "b-upload",
                    {
                      ref: "upload-button",
                      attrs: { accept: "image/*" },
                      model: {
                        value: _vm.uploadedImage,
                        callback: function($$v) {
                          _vm.uploadedImage = $$v
                        },
                        expression: "uploadedImage"
                      }
                    },
                    [
                      _c("a", { staticClass: "button" }, [
                        _c("i", { staticClass: "fas fa-upload" }),
                        _vm._v(
                          "\n               \n              " +
                            _vm._s(_vm.$t("optionsdialog.Upload image")) +
                            "\n            "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { separator: "" } }),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { key: "text", attrs: { custom: "", value: "upload" } },
                [
                  _c(
                    "b-field",
                    [
                      _c("b-input", {
                        attrs: {
                          placeholder: _vm.$t("optionsdialog.Center text"),
                          maxlength: "20"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.setText($event)
                          }
                        },
                        model: {
                          value: _vm.myCenterText,
                          callback: function($$v) {
                            _vm.myCenterText = $$v
                          },
                          expression: "myCenterText"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "control" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "button is-primary",
                              on: { click: _vm.setText }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("common.Save")) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("b-dropdown-item", { attrs: { separator: "" } }),
              _vm._v(" "),
              _vm._l(_vm.galleryPictures, function(pic) {
                return [
                  _c(
                    "b-dropdown-item",
                    { key: pic.fileName, attrs: { value: pic } },
                    [
                      _c("img", {
                        staticClass: "preview-image",
                        attrs: { src: pic.fileName }
                      }),
                      _vm._v(
                        "\n             \n            " +
                          _vm._s(pic.title) +
                          "\n          "
                      )
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }