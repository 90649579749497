<template>
  <div
    v-if="showBanner"
    style="background-image: url(/images/ukraine-flag.jpg); background-size: contain; text-align: center;"
  >
    <span class="banner-left">
      &nbsp;&nbsp;
    </span>
    <span class="banner-text">
      <a class="banner-link" target="_new" href="https://www.npr.org/2022/02/25/1082992947/ukraine-support-help?t=1645979785003">
        The world stands with Ukraine
      </a>
    </span>
    <span class="banner-right">
      &nbsp;&nbsp;
    </span>
    <span>
      <i class="fas fa-times close-icon"
        @click="hide()"
      />
    </span>
  </div>
</template>

<script>

  import './images/ukraine-flag.jpg';

  export default {
    data() {
      return { showBanner: false }
    },
    methods: {
      show() {
        this.showBanner = true;
      },
      hide() {
        this.showBanner = false;
        this.$store.commit('setSeenUkraineBanner');
      }
    }

  }

</script>

<style scoped>
  .banner-left, .banner-text, .banner-right {
    font-size: 1.2rem;
    padding: 4px 0 4px 0;
  }
  .banner-text {
    color: white;
    background-color: #00000077;
    font-weight: 600;
  }
  .banner-link {
    color: white;
    font-weight: 600;
    text-decoration: none;
  }
  .close-icon {
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: 5px;
  }
  .close-icon:hover {
    color: #BBB;
  }
  .banner-left {
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.46) 100%);
  }
  .banner-right {
    background: linear-gradient(90deg, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0) 100%);
  }
</style>
