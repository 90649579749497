var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBanner
    ? _c(
        "div",
        {
          staticStyle: {
            "background-image": "url(/images/ukraine-flag.jpg)",
            "background-size": "contain",
            "text-align": "center"
          }
        },
        [
          _c("span", { staticClass: "banner-left" }, [_vm._v("\n      \n  ")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "banner-right" }, [_vm._v("\n      \n  ")]),
          _vm._v(" "),
          _c("span", [
            _c("i", {
              staticClass: "fas fa-times close-icon",
              on: {
                click: function($event) {
                  return _vm.hide()
                }
              }
            })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "banner-text" }, [
      _c(
        "a",
        {
          staticClass: "banner-link",
          attrs: {
            target: "_new",
            href:
              "https://www.npr.org/2022/02/25/1082992947/ukraine-support-help?t=1645979785003"
          }
        },
        [_vm._v("\n      The world stands with Ukraine\n    ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }