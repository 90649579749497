var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.displayLoginDialog, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.displayLoginDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fas fa-user-alt" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("common.My account")) + "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("div", { attrs: { id: "auth-container" } })
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.displayAccountActionsDialog,
            width: 640,
            scroll: "keep"
          },
          on: {
            "update:active": function($event) {
              _vm.displayAccountActionsDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fas fa-user-alt" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("common.My account")) + "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "section",
                { staticClass: "modal-card-body can-go-dark" },
                [
                  _c("div", { staticClass: "columns is-vcentered" }, [
                    _c(
                      "div",
                      { staticClass: "column is-narrow is-one-fifth" },
                      [
                        _c("img", {
                          staticStyle: { "border-radius": "50%" },
                          attrs: { src: _vm.$store.getters.userPhotoUrl }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "is-size-4" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("profiledropdown.Signed in as", {
                                name: _vm.$store.getters.userDisplayName
                              })
                            ) +
                            "\n            "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "column is-narrow" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { size: "is-medium" },
                            on: {
                              click: function($event) {
                                return _vm.logOut()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("profiledropdown.Sign out")) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/export")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profiledropdown.Export my data")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-danger" },
                      on: {
                        click: function($event) {
                          return _vm.confirmAndDelete()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("profiledropdown.Delete my account")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }