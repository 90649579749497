<template>
  <div id="winners" class="textarea can-go-dark winner-textbox" spellcheck="false"/>
</template>

<script>
  import * as Util from './Util.js';
  import { mapGetters } from "vuex";

  export default {
    computed: {
      ...mapGetters(['winners'])
    },
    watch: {
      winners(entries) {
        const div = document.getElementById('winners');
        div.innerHTML = entries.map(entry => Util.renderEntry(entry)).join('');
      }
    }
  }
</script>

<style scoped>
  .winner-textbox {
    flex-grow: 1;
    max-height: 10000px;
    background-color: #EEE;
    overflow: auto;
    font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
                 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                 'Helvetica', 'Arial', sans-serif;
  }
</style>
