var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "overlay", staticClass: "animated-background" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "p",
        { staticClass: "animated-text", staticStyle: { color: "#FFFFFF" } },
        [_vm._v("\n      " + _vm._s(_vm.winnerText) + "\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }