import '@babel/polyfill';
import 'whatwg-fetch';
import * as Util from './Util.js';

export async function createSharedWheel(copyable, wheelConfig, idToken) {
  const shareMode = copyable ? 'copyable' : 'spin-only';
  const payload = {wheelConfig: wheelConfig.getValues(), shareMode};
  const url = '/api/v1/wheels/shared';
  const respObj = await callServer('POST', url, idToken, payload);
  return respObj.data.path;
}

export async function getSharedWheel(path) {
  const url = `/api/v1/wheels/shared/${path}`;
  const respObj = await callServer('GET', url);
  return respObj.data;
}

export async function getSavedWheel(idToken, path) {
  const url = `/api/v1/wheels/private/${path}`;
  const respObj = await callServer('GET', url, idToken);
  return respObj.data.wheel;
}

export async function getSharedWheels(idToken) {
  const url = '/api/v1/wheels/shared';
  const respObj = await callServer('GET', url, idToken);
  return respObj.data.wheels;
}

export async function deleteSharedWheel(idToken, path) {
  await callServer('DELETE', `/api/v1/wheels/${path}`, idToken);
}

export async function convertAccount(oldIdToken, newIdToken) {
  const url = '/api/v1/users/upgrade-anonymous';
  await callServer('POST', url, newIdToken, {oldIdToken});
}

export async function deleteAccount(idToken) {
  await callServer('POST', '/api/v1/users/delete', idToken);
}

export async function getSpinStats() {
  const url = '/api/v1/settings/info';
  const respObj = await callServer('GET', url);
  return respObj.data.spinStats;
}

export async function getSavedWheels(idToken, fields) {
  let url = '/api/v1/wheels/private';
  if (fields && fields.length>0) url += `?fields=${fields}`;
  const respObj = await callServer('GET', url, idToken);
  return respObj.data.wheels;
}

export async function deleteWheel(idToken, path) {
  await callServer('DELETE', `/api/v1/wheels/${path}`, idToken);
}

export async function saveWheel(idToken, wheelConfig) {
  const url = '/api/v1/wheels/private';
  const payload = { config: wheelConfig };
  const respObj = await callServer('PUT', url, idToken, payload);
  return respObj.data.path;
}

async function callServer(method, urlPath, idToken, payload) {
  const request = { method, mode: 'cors', headers: {} };
  if (idToken) {
    request.headers['Authorization'] = idToken;
  }
  if (payload) {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify(payload);
  }
  const response = await fetch(Util.getApiUrl(urlPath), request);
  const respObj = await parseResponse(response);
  return respObj;
}

async function parseResponse(response) {
  const respText = await response.text();
  if (respText.startsWith('<')) throw respText;
  const respObj = JSON.parse(respText);
  if (respObj.hasOwnProperty('error')) throw respObj.error;
  return respObj;
}
