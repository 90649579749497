import Vue from 'vue'
import VueI18n from 'vue-i18n'
import * as Locales from './Locales.js';

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
})

export async function loadLanguage(lang) {
  if (!lang) return;
  const file = Locales.getMessagesFileName(lang);
  const messages = (
    await import(/* webpackChunkName: "locale-[request]" */
                 `./locales/${file}`)
  ).default;
  i18n.setLocaleMessage(lang, messages);
  i18n.locale = lang
}
