import Vue from 'vue';
import Buefy from 'buefy';
import VueMq from 'vue-mq';
import store from './store/store.js';
import router from './router.js';
import * as i18nSetup from './i18n-setup.js';
import * as Util from './Util.js';

import 'buefy/dist/buefy.css';

import './images/icon_192.png';
import './images/icon_512.png';
import './images/apple-touch-icon.png';
import './images/favicon-16x16.png';
import './images/favicon-32x32.png';
import './images/favicon.ico';
import './images/link.png';
import './images/user_profile.png';

Util.initTracking();
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js');
  });
}

Vue.use(Buefy, {defaultIconPack: 'fas'});
Vue.use(VueMq, {
  breakpoints: {
    mobile: 900,
    desktop: Infinity,
  }
});

const i18n = i18nSetup.i18n;
i18nSetup.loadLanguage('en').then(() => {
  new Vue({
    router,
    i18n,
    store,
  }).$mount('#app')
})
