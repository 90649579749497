import * as Util from './Util.js';
import duringSpinSounds from './duringSpinSounds.json';

const sounds = {};
let musicPlayingNow;
let musicVolumePercent;
let soundPlayingNow;
if (window.speechSynthesis) {
  window.speechSynthesis.getVoices();
}

const afterSpinSounds = [
  {name: 'sounds.No sound', value: 'no-sound'},
  {name: 'sounds.Subdued applause', value: 'applause-sound-soft', file: '/SMALL_CROWD_APPLAUSE-Yannick_Lemieux-1268806408-soft.mp3'},
  {name: 'sounds.Joke punchline', value: 'joke-punchline', file: '/113191636-comedy-joke-punchline-drum-tus.mp3'},
  {name: 'sounds.Announcement bell', value: 'announcement-bell-sound', file: '/053512570-bell-announcement-06.mp3'},
  {name: 'sounds.Twinkling star', value: 'twinkling-star-sound', file: '/060880433-star-ding-04.mp3'},
  {name: 'sounds.Correct answer ding', value: 'correct-answer-ding-sound', file: '/064511375-correct-answer-ding-4.mp3'},
  {name: 'sounds.Swoosh ding', value: 'swoosh-ding-sound', file: '/065214573-swoosh-ui-success-ding-complet.mp3'},
  {name: 'sounds.Synth bell', value: 'synth-bell-sound', file: '/095397584-synth-bell-announcement-01.mp3'},
  {name: 'sounds.Notification bell', value: 'notification-bell-sound', file: '/146126341-bell-star-notification-04-slow.mp3'},
  {name: 'sounds.Mystery bell', value: 'mystery-bell-sound', file: '/152715407-bell-mystery-08.mp3'},
  {name: 'sounds.Microwave ding', value: 'microwave-ding-sound', file: '/041253901-microwave-oven-bell-ding-02.mp3'},
  {name: 'sounds.Applause', value: 'applause-sound', file: '/SMALL_CROWD_APPLAUSE-Yannick_Lemieux-1268806408.mp3'},
  {name: 'sounds.Fanfare', value: 'fanfare-sound', file: '/350428__benjaminharveydesign__trumpet-fanfare.mp3'},
  {name: 'sounds.Bell ringing', value: 'bell-sound', file: '/370743__podsburgh__winner-metal-bell-ringing-remix.mp3'},
  {name: 'sounds.Cymbals', value: 'cymbal-sound', file: '/425432__trivialaccapella__18-crash-hit-soft.mp3'},
  {name: 'sounds.Thunder', value: 'thunder', file: '/076042682-huge-thunder-7.mp3'},
  {name: 'sounds.Pan flute', value: 'pan-flute', file: '/090393914-western-pan-flute.mp3'},
  {name: 'sounds.Evil laugh', value: 'evil-laugh', file: '/137429128-evil-man-laugh-1.mp3'},
  {name: 'sounds.Old phone ringing', value: 'old-phone-sound', file: '/274436__hockinfinger__stromberg-carlson-1543.mp3'},
  {name: 'sounds.Alarm clock', value: 'alarm-clock-sound', file: '/468081__kinoton__egg-timer-ticking-ringing.mp3'},
  {name: 'sounds.Military trumpet', value: 'military-trumpet-sound', file: '/008723787-bugle-military.mp3'},
  {name: 'sounds.Fireworks', value: 'fireworks-sound', file: '/008747751-fireworks.mp3'},
  {name: 'sounds.Basketball buzzer', value: 'basketball-buzzer-sound', file: '/012744931-basketball-court-buzzer.mp3'},
  {name: 'sounds.Hooray', value: 'hooray-sound', file: '/079197409-voice-clip-crowd-hooray.mp3'},
  {name: 'sounds.Childrens hooray', value: 'childrens-hooray-sound', file: '/010799171-children-loud-hooray.mp3'},
  {name: 'sounds.Xylophone ascending', value: 'xylophone-ascending', file: '/000206355-xylophone-ascend.mp3'},
  {name: 'sounds.Game win ding', value: 'game-win-ding', file: '/006143190-carnival-game-win-ding-05.mp3'},
  {name: 'sounds.Horse', value: 'horse', file: '/008700295-horse.mp3'},
  {name: 'sounds.Wolf howl', value: 'wolf-howl', file: '/008833564-wolf-howl-one-shot.mp3'},
  {name: 'sounds.Cartoon whistle', value: 'cartoon-whistle', file: '/008902693-whistle-cartoon.mp3'},
  {name: 'sounds.Cat meow', value: 'cat-meow', file: '/010706374-cat-meow-1.mp3'},
  {name: 'sounds.Sad trombone', value: 'sad-trombone', file: '/012481405-cartoon-failure-3.mp3'},
  {name: 'sounds.Crash', value: 'crash', file: '/021498952-crash-comical.mp3'},
  {name: 'sounds.Ghost', value: 'ghost', file: '/026239577-burning-ghost-distant.mp3'},
  {name: 'sounds.Cinematic drum impact', value: 'cinematic-drum-impact', file: '/032165978-cinematic-drum-impact.mp3'},
  {name: 'sounds.Water splash', value: 'water-splash', file: '/039809461-water-splash-sfx-1.mp3'},
  {name: 'sounds.Referee whistle', value: 'referee-whistle', file: '/041918725-referee-whistle.mp3'},
  {name: 'sounds.Dog bark', value: 'dog-bark', file: '/042791251-big-dog-single-bark.mp3'},
  {name: 'sounds.Boing', value: 'boing', file: '/043133601-cartoon-spring-boing-02.mp3'},
  {name: 'sounds.Boing 2', value: 'boing-2', file: '/079311639-boing-cartoon-13.mp3'},
  {name: 'sounds.Angel choir', value: 'angel-choir', file: '/053069047-voice-heaven-angel-sound-e-maj.mp3'},
  {name: 'sounds.Lion roar', value: 'lion-roar', file: '/062570152-beast-lion-demon-roar-no-07.mp3'},
  {name: 'sounds.Lost game', value: 'lost-game', file: '/066475397-funny-slippery-game-over-losin.mp3'},
  {name: 'sounds.Bird chirps', value: 'bird-chirps', file: '/079311332-bird-chirp-cartoon-3.mp3'},
  {name: 'sounds.Taiko drum', value: 'taiko-drum', file: '/083962303-short-taiko-drum-jingle.mp3'},
  {name: 'sounds.Horror scream', value: 'horror-scream', file: '/127118749-horror-scream-08.mp3'},
  {name: 'sounds.Carnival shout', value: 'carnival-shout', file: '/144838895-male-carnival-shout-1.mp3'},
  {name: 'sounds.Read out the winning name (option 1)', value: 'read-winner-sound'},
  {name: 'sounds.Read out the winning name (option 2)', value: 'read-winner-sound-2'},
]

export function getDuringSpinSounds() {
  return duringSpinSounds;
}

export function getAfterSpinSounds() {
  return afterSpinSounds;
}

export function playTick(volumePercent) {
  playSound('/ding.mp3', volumePercent);
}

export function playClick(volumePercent) {
  playSound('/Tick-DeepFrozenApps-397275646.mp3', volumePercent);
}

export async function playAfterSpin(trackName, winningText, volumePercent, locale) {
  if (trackName=='read-winner-sound' || trackName=='read-winner-sound-2') {
    if (window.speechSynthesis && winningText) {
      const utterThis = new SpeechSynthesisUtterance(winningText);
      utterThis.voice = getVoice(trackName, locale);
      utterThis.volume = volumePercent / 100;
      window.speechSynthesis.speak(utterThis);
    }
  }
  else {
    await import(/* webpackChunkName: "howler" */ 'howler');
    const file = afterSpinSounds.find(sound => sound.value==trackName).file;
    if (file) soundPlayingNow = new Howl({ src: [file], autoplay: true, volume: volumePercent / 100 });
  }
}

function getVoice(trackName, language) {
  let voices = window.speechSynthesis.getVoices();
  if (language=='en') language = 'en-US';
  if (language=='es') language = 'es-ES';
  let languageVoices = voices.filter(v=>v.lang.startsWith(language));
  if (trackName=='read-winner-sound' || languageVoices.length < 2) {
    return languageVoices[0];
  }
  else {
    return languageVoices[1];
  }
}

export function stopAfterSpinSound() {
  if (soundPlayingNow) soundPlayingNow.fade(1, 0, 1);
  soundPlayingNow = null;
}

export async function startMusic(trackName, volumePercent) {
  await import(/* webpackChunkName: "howler" */ 'howler');
  if (trackName=='random-music') trackName = getRandomMusicTrackValue();
  if (trackName.startsWith('random:')) trackName = getRandomMusicTrackInGenreValue(trackName);
  const file = getMusicFile(trackName);
  if (file) {
    musicPlayingNow = new Howl({ src: [file], autoplay: true, loop: true, volume: volumePercent / 100 });
    musicVolumePercent = volumePercent;
  }
}

export function fadeMusic() {
  if (musicPlayingNow) musicPlayingNow.fade(musicVolumePercent/100, 0, 1000);
  musicPlayingNow = null;
}

export function stopMusicNow() {
  if (musicPlayingNow) musicPlayingNow.stop();
  musicPlayingNow = null;
}

async function playSound(soundName, volumePercent) {
  await import(/* webpackChunkName: "howler" */ 'howler');
  try {
    if (sounds[soundName]) {
      sounds[soundName].volume(volumePercent / 100);
      sounds[soundName].play();
    }
    else {
      sounds[soundName] = new Howl({src: [soundName], autoplay: true, volume: volumePercent / 100});
    }
  }
  catch (ex) {
    Util.trackException(ex);
  }
}

export function preloadSounds(duringSpinSound, afterSpinSound) {
  try {
    preloadFile(getMusicFile(duringSpinSound));
    if (duringSpinSound == 'ticking-sound') preloadFile('/ding.mp3');
    preloadFile(afterSpinSounds.find(sound => sound.value==afterSpinSound).file);
  }
  catch (ex) {
    Util.trackException(ex);
  }
}

async function preloadFile(file) {
  await import(/* webpackChunkName: "howler" */ 'howler');
  if (file && !sounds[file]) {
    sounds[file] = new Howl({src: [file]});
  }
}

function getMusicFile(soundValue) {
  for (const category of Object.keys(duringSpinSounds)) {
    for (const soundName of Object.keys(duringSpinSounds[category])) {
      const sound = duringSpinSounds[category][soundName];
      if (sound.value == soundValue) {
        return sound.musicFile;
      }
    }
  }
}

function getRandomMusicTrackValue() {
  const allTrackValues = [];
  for (const category of Object.keys(duringSpinSounds)) {
    for (const soundName of Object.keys(duringSpinSounds[category])) {
      const sound = duringSpinSounds[category][soundName];
      if (sound.musicFile) allTrackValues.push(sound.value);
    }
  }
  const trackNumber = Util.getRandomInt(0, allTrackValues.length-1);
  return allTrackValues[trackNumber];
}

function getRandomMusicTrackInGenreValue(trackName) {
  const match = trackName.match(/random: (.*)/);
  const genreName = match ? match[1] : 'xxxxx';
  const trackValuesInGenre = [];
  for (const category of Object.keys(duringSpinSounds)) {
    if (category.includes(genreName)) {
      for (const soundName of Object.keys(duringSpinSounds[category])) {
        const sound = duringSpinSounds[category][soundName];
        if (sound.musicFile) trackValuesInGenre.push(sound.value);
      }
    }
  }
  const trackNumber = Util.getRandomInt(0, trackValuesInGenre.length-1);
  return trackValuesInGenre[trackNumber];
}
