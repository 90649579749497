var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      attrs: {
        "append-to-body": "",
        "aria-role": "menu",
        scrollable: "",
        "max-height": "400",
        "trap-focus": ""
      },
      scopedSlots: _vm._u([
        {
          key: "trigger",
          fn: function(ref) {
            var active = ref.active
            return [
              _c("b-button", {
                attrs: {
                  label: _vm.$t("optionsdialog.Apply a theme"),
                  type: "is-primary",
                  "icon-right": active ? "caret-up" : "caret-down",
                  disabled: _vm.disabled
                }
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.colorTheme,
        callback: function($$v) {
          _vm.colorTheme = $$v
        },
        expression: "colorTheme"
      }
    },
    [
      _vm._v(" "),
      _vm._l(Object.keys(_vm.colorThemeCategories), function(
        themeCategoryName
      ) {
        return [
          _c("b-dropdown-item", { key: themeCategoryName }, [
            _c("b", [_vm._v(_vm._s(themeCategoryName))])
          ]),
          _vm._v(" "),
          _vm._l(
            Object.keys(_vm.colorThemeCategories[themeCategoryName]),
            function(themeName) {
              return [
                _c(
                  "b-dropdown-item",
                  {
                    key: themeName,
                    attrs: { value: themeCategoryName + ", " + themeName }
                  },
                  [
                    _vm._v("\n          " + _vm._s(themeName) + "\n        "),
                    _vm._l(
                      _vm.colorThemeCategories[themeCategoryName][themeName],
                      function(color) {
                        return [
                          _c(
                            "svg",
                            {
                              staticStyle: { "margin-left": "5px" },
                              attrs: { width: "12", height: "12" }
                            },
                            [
                              _c("rect", {
                                style:
                                  "fill:" +
                                  color +
                                  ";stroke-width:1;stroke:rgb(0,0,0)",
                                attrs: { width: "12", height: "12" }
                              })
                            ]
                          )
                        ]
                      }
                    )
                  ],
                  2
                )
              ]
            }
          ),
          _vm._v(" "),
          _c("b-dropdown-item", { attrs: { separator: "" } })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }