exports.list = [
  {fileName: "/images/autumn-leaves.jpg", title: "Autumn leaves"},
  {fileName: "/images/beach-ball.jpg", title: "Beach ball"},
  {fileName: "/images/birth-of-the-universe.jpg", title: "Birth of the universe"},
  {fileName: "/images/blue-frost-pattern.jpg", title: "Blue frost pattern"},
  {fileName: "/images/blue-glowing-vortex.jpg", title: "Blue glowing vortex"},
  {fileName: "/images/blue-swirl.jpg", title: "Blue swirl"},
  {fileName: "/images/caffe-latte.jpg", title: "Caffe latte"},
  {fileName: "/images/cat-portrait.jpg", title: "Cat portrait"},
  {fileName: "/images/chocolate-chip-cookie.jpg", title: "Chocolate chip cookie"},
  {fileName: "/images/christmas-wrapping-paper.jpg", title: "Christmas wrapping paper"},
  {fileName: "/images/disco-ball.jpg", title: "Disco ball"},
  {fileName: "/images/dog-portrait.jpg", title: "Dog portrait"},
  {fileName: "/images/donut.jpg", title: "Donut"},
  {fileName: "/images/gerbera-flower.jpg", title: "Gerbera flower"},
  {fileName: "/images/globe.jpg", title: "Globe"},
  {fileName: "/images/green-grass.jpg", title: "Green grass"},
  {fileName: "/images/green-leaves.jpg", title: "Green leaves"},
  {fileName: "/images/halloween.jpg", title: "Halloween"},
  {fileName: "/images/moon.jpg", title: "Moon"},
  {fileName: "/images/night-sky.jpg", title: "Night sky"},
  {fileName: "/images/orange-slice.jpg", title: "Orange slice"},
  {fileName: "/images/palm-trees.jpg", title: "Palm trees"},
  {fileName: "/images/rainbow.jpg", title: "Rainbow"},
  {fileName: "/images/soccer-ball.jpg", title: "Soccer ball"},
  {fileName: "/images/spiral-nebula.jpg", title: "Spiral nebula"},
  {fileName: "/images/spiral-tie-dye.jpg", title: "Spiral tie-dye"},
  {fileName: "/images/star-trails.jpg", title: "Star trails"},
  {fileName: "/images/water-in-pool.jpg", title: "Water in pool"},
  {fileName: "/images/water-swirl.jpg", title: "Water swirl"},
  {fileName: "/images/whirlpool.jpg", title: "Whirlpool"},
  {fileName: "/images/wood.jpg", title: "Wood"},
  {fileName: "/images/year-of-the-tiger.jpg", title: "Year of the Tiger"},
]
