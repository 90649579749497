var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card-content" }, [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("yearCounter", {
            attrs: {
              title: "Wheel spins",
              increment: _vm.spinsPerSecond,
              fps: 0.333333
            }
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("yearCounter", {
            attrs: {
              title: "Hours of spinning",
              increment: _vm.hoursSpunPerSecond,
              fps: _vm.hoursSpunPerSecond
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-header-title" }, [
        _c("img", { attrs: { src: "/images/favicon-32x32.png" } }),
        _vm._v("\n       \n      "),
        _c("h4", { staticClass: "title is-4" }, [
          _vm._v("\n        This year's numbers\n      ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }