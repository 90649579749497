import * as FullScreen from '../FullScreen.js';

export default {
  state: {
    fullScreen: false,
    online: true,
  },
  getters: {
    fullScreen: state => state.fullScreen,
    online: state => state.online,
  },
  mutations: {
    enterFullScreen(state) {
      state.fullScreen = true;
      FullScreen.turnOnFullscreen();
    },
    exitFullScreen(state) {
      state.fullScreen = false;
      FullScreen.turnOffFullscreen();
    },
    fullScreenChanged(state) {
      state.fullScreen = FullScreen.fullscreenOn();
    },
    setOnline(state, online) {
      state.online = online;
    },
  }
}
