<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          Can the wheel be rigged?
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <p>
          There is no functionality to determine which entry will win ahead of
          time. When you click the wheel, it accelerates for exactly one second,
          then it is set to a random rotation between 0 and 360 degrees, and
          finally it decelerates to a stop. The setting of a random rotation is
          not visible to the naked eye as it happens when the wheel is spinning
          quite fast.
        </p>
        <p>
          <router-link to="/faq/randomness">Randomness of the wheel</router-link>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import '../images/favicon-32x32.png';

  export default {
  }
</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
