<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          How to use the wheel spinner
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <p>
          It's easy: type in your entries in the textbox to the right of the
          wheel, then click the wheel to spin it and get a random winner.
          To make the wheel your own by customizing the colors, sounds, and spin
          time, click
          <span class="tag is-info"><i class="fas fa-palette"></i>&nbsp;Customize</span>
          at the top of the page.
        </p>
        <p>
          <a href="/user-reviews-and-tutorials">
            Video reviews and tutorials by users.
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import '../images/favicon-32x32.png';

  export default {
  }
</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
