<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          What can I use this wheel spinner for?
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        Every day we hear from people who use our website in new ways:
        <ul>
          <li>
            Random name picker in the classroom: pick which student will answer
            the next question. <a href="/how-to-use-in-the-classroom">How to use
            it in the classroom</a>.
          </li>
          <li>
            If you are a retailer, spin the wheel to pick which loyal customer
            will get the monthly giveaway.
          </li>
          <li>
            When you give a presentation, use the wheel spinner to pick a lucky
            winner among the attendees who turned in the survey.
          </li>
          <li>
            Random name picker at work: in your daily standup meeting at work,
            randomize who speaks first.
          </li>
          <li>
            If you are overwhelmed by your to do items, put them on a wheel and
            spin to find which one to start with.
          </li>
          <li>
            If you can't agree on what to have for dinner, put the alternatives
            on the wheel and spin.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import '../images/favicon-32x32.png';

  export default {
  }
</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
