<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          Does Wheel of Names use renewable energy?
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <p>
          We are proud to share that 100% of the electricity that powers our
          servers is renewable, and 93% comes from carbon-free energy sources.
        </p>
        <p>
          <router-link to="/faq/sustainability">Sustainability</router-link>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import '../images/favicon-32x32.png';

  export default {
  }
</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
