const nonEnglishDomains = [ {partialName: 'noms', locale: 'fr'} ];
const locales = [
  {name: 'ar',    humanName: 'العربية',          flag: '☪️',          googleName: 'ar_AR', twitterName: 'ar',    file: 'ar-EG.json'},
  {name: 'az',    humanName: 'Azərbaycanca',     flag: '🇦🇿',         googleName: 'az_AZ', twitterName: 'az',    file: 'az-Latn-AZ.json'},
  {name: 'bg',    humanName: 'български',        flag: '🇧🇬',         googleName: 'bg_BG', twitterName: 'bg',    file: 'bg-BG.json'},
  {name: 'bn',    humanName: 'বাংলা',             flag: '🇧🇩',         googleName: 'bn_IN', twitterName: 'bn',    file: 'bn-IN.json'},
  {name: 'bs',    humanName: 'Bosanski',         flag: '🇧🇦',         googleName: 'bs_BA', twitterName: 'bs',    file: 'bs-Latn-BA.json'},
  {name: 'ca',    humanName: 'Català',           flag: '🇪🇸',         googleName: 'ca_ES', twitterName: 'ca',    file: 'ca-ES.json'},
  {name: 'cs',    humanName: 'Čeština',          flag: '🇨🇿',         googleName: 'cs_CZ', twitterName: 'cs',    file: 'cs-CZ.json'},
  {name: 'cy',    humanName: 'Cymraeg',          flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',         googleName: 'cy_GB', twitterName: 'cy',    file: 'cy-GB.json'},
  {name: 'da',    humanName: 'Dansk',            flag: '🇩🇰',         googleName: 'da_DK', twitterName: 'da',    file: 'da-DK.json'},
  {name: 'de',    humanName: 'Deutsch',          flag: '🇩🇪',         googleName: 'de_DE', twitterName: 'de',    file: 'de-DE.json'},
  {name: 'el',    humanName: 'Eλληνικά',         flag: '🇬🇷',         googleName: 'el_GR', twitterName: 'el',    file: 'el-GR.json'},
  {name: 'en-PI', humanName: 'English (Pirate)', flag: '🏴‍☠️',         googleName: 'en_US', twitterName: 'en',    file: 'en-PI.json'},
  {name: 'en',    humanName: 'English',          flag: '🇺🇸 🇬🇧 🇨🇦 🇦🇺', googleName: 'en_US', twitterName: 'en',    file: 'en-US.json'},
  {name: 'es',    humanName: 'Español',          flag: '🇲🇽 🇨🇴 🇦🇷 🇪🇸', googleName: 'es_ES', twitterName: 'es',    file: 'es-ES.json'},
  {name: 'et',    humanName: 'Estonian',         flag: '🇪🇪',         googleName: 'et_EE', twitterName: 'et',    file: 'et-EE.json'},
  {name: 'fa',    humanName: 'فارسی',            flag: '🇮🇷',         googleName: 'fa_IR', twitterName: 'fa',    file: 'fa-IR.json'},
  {name: 'fi',    humanName: 'Suomi',            flag: '🇫🇮',         googleName: 'fi_FI', twitterName: 'fi',    file: 'fi-FI.json'},
  {name: 'fil',   humanName: 'Filipino',         flag: '🇵🇭',         googleName: 'fil_PH', twitterName: 'fil',  file: 'fil-PH.json'},
  {name: 'fr',    humanName: 'Français',         flag: '🇫🇷 🇨🇦 🇧🇪 🇨🇭', googleName: 'fr_FR', twitterName: 'fr',    file: 'fr-FR.json'},
  {name: 'gl',    humanName: 'Galego',           flag: '🇪🇸',         googleName: 'gl_ES', twitterName: 'gl',    file: 'gl-ES.json'},
  {name: 'gu',    humanName: 'ગુજરાતી',            flag: '🇮🇳',         googleName: 'gu_IN', twitterName: 'gu',    file: 'gu-IN.json'},
  {name: 'he',    humanName: 'עברית',            flag: '🇮🇱',         googleName: 'he_IL', twitterName: 'he',    file: 'he-IL.json'},
  {name: 'hi',    humanName: 'हिंदी',              flag: '🇮🇳',         googleName: 'hi_IN', twitterName: 'hi',    file: 'hi-IN.json'},
  {name: 'hr',    humanName: 'Hrvatski',         flag: '🇭🇷',        googleName: 'hr_HR', twitterName: 'hr',    file: 'hr-HR.json'},
  {name: 'hu',    humanName: 'Magyar',           flag: '🇭🇺',        googleName: 'hu_HU', twitterName: 'hu',    file: 'hu-HU.json'},
  {name: 'hy',    humanName: 'Հայերեն',          flag: '🇦🇲',        googleName: 'hy_AM', twitterName: 'hy',    file: 'hy-AM.json'},
  {name: 'id',    humanName: 'Bahasa Indonesia', flag: '🇮🇩',        googleName: 'id_ID', twitterName: 'id',    file: 'id-ID.json'},
  {name: 'it',    humanName: 'Italiano',         flag: '🇮🇹',        googleName: 'it_IT', twitterName: 'it',    file: 'it-IT.json'},
  {name: 'ja',    humanName: '日本語',            flag: '🇯🇵',        googleName: 'ja_JP', twitterName: 'ja',    file: 'ja-JP.json'},
  {name: 'ka',    humanName: 'ქართული',          flag: '🇬🇪',        googleName: 'ka_GE', twitterName: 'ka',    file: 'ka-GE.json'},
  {name: 'kk',    humanName: 'Қазақ тілі',       flag: '🇰🇿',        googleName: 'kk_KZ', twitterName: 'kk',    file: 'kk-KZ.json'},
  {name: 'ko',    humanName: '한국어',             flag: '🇰🇷',       googleName: 'ko_KR', twitterName: 'ko',    file: 'ko-KR.json'},
  {name: 'lo',    humanName: 'ພາສາລາວ',           flag: '🇱🇦',        googleName: 'lo_LA', twitterName: 'lo',    file: 'lo-LA.json'},
  {name: 'lt',    humanName: 'Lietuvių',         flag: '🇱🇹',       googleName: 'lt_LT', twitterName: 'lt',    file: 'lt-LT.json'},
  {name: 'lv',    humanName: 'Latviešu',         flag: '🇱🇻',       googleName: 'lv_LV', twitterName: 'lv',    file: 'lv-LV.json'},
  {name: 'mk',    humanName: 'Mакедонски',       flag: '🇲🇰',       googleName: 'mk_MK', twitterName: 'mk',    file: 'mk-MK.json'},
  {name: 'mn',    humanName: 'Mонгол',           flag: '🇲🇳',       googleName: 'mn_MN', twitterName: 'mn',    file: 'mn-MN.json'},
  {name: 'ms',    humanName: 'Bahasa Melayu',    flag: '🇲🇾',       googleName: 'ms_MY', twitterName: 'ms',    file: 'ms-MY.json'},
  {name: 'nl',    humanName: 'Nederlands',       flag: '🇳🇱',       googleName: 'nl_NL', twitterName: 'nl',    file: 'nl-NL.json'},
  {name: 'no',    humanName: 'Norsk',            flag: '🇳🇴',       googleName: 'nb_NO', twitterName: 'no',    file: 'nb-NO.json'},
  {name: 'pl',    humanName: 'Polski',           flag: '🇵🇱',       googleName: 'pl_PL', twitterName: 'pl',    file: 'pl-PL.json'},
  {name: 'pt',    humanName: 'Português',        flag: '🇧🇷 🇦🇴 🇵🇹',  googleName: 'pt_BR', twitterName: 'pt',    file: 'pt-PT.json'},
  {name: 'ro',    humanName: 'Română',           flag: '🇷🇴',       googleName: 'ro_RO', twitterName: 'ro',    file: 'ro-RO.json'},
  {name: 'ru',    humanName: 'Pусский',          flag: '🇷🇺',       googleName: 'ru_RU', twitterName: 'ru',    file: 'ru-RU.json'},
  {name: 'sl',    humanName: 'Slovenščina',      flag: '🇸🇮',       googleName: 'sl_SL', twitterName: 'sl',    file: 'sl-SI.json'},
  {name: 'sq',    humanName: 'Shqip',            flag: '🇦🇱',       googleName: 'sq_AL', twitterName: 'sq',    file: 'sq-AL.json'},
  {name: 'sr',    humanName: 'Srpski',           flag: '🇷🇸',       googleName: 'sr_SP', twitterName: 'sr',    file: 'sr-Latn-RS.json'},
  {name: 'sv',    humanName: 'Svenska',          flag: '🇸🇪',       googleName: 'sv_SE', twitterName: 'sv',    file: 'sv-SE.json'},
  {name: 'ta',    humanName: 'தமிழ்',              flag: '🇮🇳 🇱🇰 🇸🇬', googleName: 'ta_IN', twitterName: 'ta',    file: 'ta-IN.json'},
  {name: 'th',    humanName: 'ไทย',              flag: '🇹🇭',       googleName: 'th_TH', twitterName: 'th',    file: 'th-TH.json'},
  {name: 'tr',    humanName: 'Türkçe',           flag: '🇹🇷',       googleName: 'tr_TR', twitterName: 'tr',    file: 'tr-TR.json'},
  {name: 'uk',    humanName: 'Українська',       flag: '🇺🇦',       googleName: 'uk_UA', twitterName: 'uk',    file: 'uk-UA.json'},
  {name: 'vi',    humanName: 'Tiếng Việt',       flag: '🇻🇳',       googleName: 'vi_VN', twitterName: 'vi',    file: 'vi-VN.json'},
  {name: 'zh-CN', humanName: '简体中文',          flag: '🇨🇳',       googleName: 'zh_CN', twitterName: 'zh-cn', file: 'zh-CN.json'},
  {name: 'zh-HK', humanName: '繁體中文',          flag: '🇹🇼 🇭🇰',     googleName: 'zh_TW', twitterName: 'zh-tw', file: 'zh-HK.json'}
]

export function getLocale(hostName, pathName) {
  return getPathLocale(pathName) || getDomainLocale(hostName);
}

export function getRelativeUrl(hostName, locale) {
  return getDomainLocale(hostName)==locale ? '/' : `/${locale}/`;
}

export function getAbsoluteUrl(hostName, locale, path) {
  const link = hostName + getRelativeUrl(hostName, locale) + '/' + path;
  return link.replace('//', '/');
}

export function getLoginLocale(providerName, locale) {
  const matchedLocales = locales.filter(l => l.name == locale);
  if (providerName.toLowerCase() == 'twitter') {
    return matchedLocales.reduce((acc, current) => current.twitterName, 'en_US');
  }
  else {
    return matchedLocales.reduce((acc, current) => current.googleName, 'en_US');
  }
}

export function getOfficialList(hostName) {
  return locales.map(l => {
    return {
      name: l.name,
      url: hostName + getRelativeUrl(hostName, l.name)
    }
  }).filter(l => l.name!='en-PI');
}

export function getMessagesFileName(locale) {
  return locales.filter(l => l.name==locale)
                .reduce((acc, current) => current.file, 'en-US.json');
}

export function getLangTipLocale(systemLocale, navigatorLanguages) {
  if (!navigatorLanguages || !navigatorLanguages.length) return '';
  const userLocale = navigatorLanguages[0];
  const shouldShowLanguageTip = (!areSameLocale(userLocale, systemLocale) &&
                                 localeIsSupported(userLocale));
  return shouldShowLanguageTip ? getClosestSupportedLocale(userLocale) : '';
}

export function getNamesForAll(includeFlags) {
  return locales
            .map(locale => {
              let humanName = locale.humanName;
              if (includeFlags) humanName += ' ' + locale.flag;
              return {name: locale.name, humanName: humanName}
            })
            .sort((a, b) => a.humanName.localeCompare(b.humanName))
}

export function getDomainLocale(hostName) {
  return nonEnglishDomains
            .filter(domain => hostName.includes(domain.partialName))
            .reduce((acc, current) => current.locale, 'en');
}

function areSameLocale(locale1, locale2) {
  if (locale1.length==locale2.length) {
    return (locale1 == locale2);
  }
  else {
    return (getLangFromLocale(locale1) == getLangFromLocale(locale2));
  }
}

function getLangFromLocale(locale) {
  return locale.split('-')[0];
}

function localeIsSupported(locale) {
  return !!locales.find(l => areSameLocale(locale, l.name));
}

function getClosestSupportedLocale(locale) {
  return locales.find(l => areSameLocale(locale, l.name)).name;
}

function getPathLocale(pathName) {
  return locales
            .map(locale => locale.name)
            .find(locale => {
              const re1 = new RegExp(`\\/${locale}\\/?$`);
              const re2 = new RegExp(`\\/${locale}\\/`);
              return (pathName.match(re1) || pathName.match(re2));
            })
}
