import * as ServerFunctions from '../ServerFunctions.js';
import * as Firebase from '../Firebase.js';

export default {
  state: {
    userPhotoUrl: '',
    userDisplayName: '',
    savedWheels: [],
    sharedWheels: []
  },
  getters: {
    userPhotoUrl: state => state.userPhotoUrl,
    userDisplayName: state => state.userDisplayName,
    savedWheels: state => state.savedWheels,
    sharedWheels: state => state.sharedWheels,
  },
  mutations: {
    setUserPhotoUrl(state, userPhotoUrl) {
      state.userPhotoUrl = userPhotoUrl || '/images/user_profile.png';
    },
    setUserDisplayName(state, userDisplayName) {
      state.userDisplayName = userDisplayName;
    },
    setSavedWheels(state, savedWheels) {
      state.savedWheels = savedWheels;
    },
    setSharedWheels(state, sharedWheels) {
      state.sharedWheels = sharedWheels;
    },
    clearUser(state) {
      state.userPhotoUrl = '';
      state.userDisplayName = '';
      state.savedWheels = [];
      state.sharedWheels = [];
    },
  },
  actions: {
    async userIsLoggedIn({state, commit, rootState}) {
      await Firebase.loadLibraries();
      const loggedIn = await Firebase.userIsLoggedIn();
      if (loggedIn) {
        const user = await Firebase.getLoggedInUser();
        commit('setUserPhotoUrl', user.photoURL);
        commit('setUserDisplayName', user.displayName);  
      }
      return loggedIn;
    },
    async logOut({state, commit, rootState}) {
      Firebase.logOut();
      commit('clearUser');
    },
    async deleteAccount({state, commit, rootState}) {
      const idToken = await Firebase.getUserIdToken();
      await ServerFunctions.deleteAccount(idToken);
      Firebase.logOut();
      commit('clearUser');
    },
    async loginAnonymously(context) {
      await Firebase.logInAnonymously();
    },
    async loginWithUi({state, commit, rootState}, elementName) {
      const anonymousTokenId = await Firebase.getAnonymousTokenId();
      const user = await Firebase.loadAuthUserInterface(elementName);
      commit('setUserPhotoUrl', user.photoURL);
      commit('setUserDisplayName', user.displayName);
      await ServerFunctions.convertAccount(
        anonymousTokenId, await Firebase.getUserIdToken()
      );
    },
    async logInToSheets({state, commit, rootState}, locale) {
      await Firebase.loadLibraries();
      const anonymousTokenId = await Firebase.getAnonymousTokenId();
      const accessToken = await Firebase.logInToSheets(locale);
      const user = await Firebase.getLoggedInUser();
      commit('setUserPhotoUrl', user.photoURL);
      commit('setUserDisplayName', user.displayName);
      await ServerFunctions.convertAccount(
        anonymousTokenId, await Firebase.getUserIdToken()
      );
      return accessToken;
    },
    async getUid() {
      return await Firebase.getUid();
    },
    async loadSavedWheels(context) {
      const idToken = await Firebase.getUserIdToken();
      const wheels = await ServerFunctions.getSavedWheels(idToken);
      context.commit('setSavedWheels', wheels.map(w => w.config));
    },
    async loadWheel(context, path) {
      const idToken = await Firebase.getUserIdToken();
      const wheel = await ServerFunctions.getSavedWheel(idToken, path);
      return wheel;
    },
    async getSavedWheels(context, { fields }) {
      const idToken = await Firebase.getUserIdToken();
      let wheels = await ServerFunctions.getSavedWheels(idToken, fields);
      wheels = wheels.sort((a, b) =>
        a.config.title.localeCompare(b.config.title, 'en', { numeric: true, sensitivity: 'base' })
      );
      return wheels;
    },
    async deleteSavedWheel(context, wheelPath) {
      const idToken = await Firebase.getUserIdToken();
      await ServerFunctions.deleteWheel(idToken, wheelPath);
    },
    async saveWheel(context, wheelConfig) {
      const idToken = await Firebase.getUserIdToken();
      await ServerFunctions.saveWheel(idToken, wheelConfig.getValues());
    },
    async loadSharedWheels(context) {
      const idToken = await Firebase.getUserIdToken();
      const wheels = await ServerFunctions.getSharedWheels(idToken);
      context.commit('setSharedWheels', wheels);
    },
    async shareWheel(context, {wheelConfig, copyableWheel}) {
      const idToken = await Firebase.getUserIdToken();
      return await ServerFunctions.createSharedWheel(
        copyableWheel, wheelConfig, idToken
      );
    },
    async deleteSharedWheel(context, path) {
      const idToken = await Firebase.getUserIdToken();
      await ServerFunctions.deleteSharedWheel(idToken, path);
      const wheels = await ServerFunctions.getSharedWheels(idToken);
      context.commit('setSharedWheels', wheels);
    }
  }
}
