<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        <img src="/images/favicon-32x32.png">
        &nbsp;
        <h4 class="title is-4">
          Is my data private?
        </h4>
      </div>
    </div>
    <div class="card-content">
      <div class="content">
        <p>
          We are committed to protecting and respecting your privacy and the 
          security of your data. We comply with GDPR, CCPA, SB 190, SB 1392, and
          we closely monitor changes to them. We follow industry best practices
          for data encryption and backups.
        </p>
        <p>
          <a href="/privacy-policy.html">How we safeguard your privacy</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import '../images/favicon-32x32.png';

  export default {
  }
</script>

<style scoped>
  img {
    height: 1.5em;
    vertical-align: baseline;
  }
</style>
