<template>
  <div v-if="showAd">
    <div class="ad-declaration">
      <a @click="toggleReportingBox()">{{ $t('app.Report bad ad') }}</a>
      <span style="float:right;">
        {{ $t('app.Close ad') }}
        <b-button @click="hideAd()" type="is-light" pack="far" icon-left="times-circle" size="is-small" style="margin-left:10px"></b-button>
      </span>
    </div>
    <div class="ad-declaration">
      <div v-if="showReporting" class="card">
        <div class="card-header">
          <div class="card-header-title">
            How to report an inappropriate ad
          </div>
        </div>
        <div class="card-content">
          <ol>
            <li>
              Take a screenshot of this page.
              (Unsure how to take a screenshot?
              <a target="_new" href="https://www.take-a-screenshot.org/">See instructions here</a>.)
            </li>
            <li>
              Create an email with the subject line <b>Bad ad</b>
            </li>
            <li>
              Attach the screenshot to the email and send it to admin&#64;wheelofnames&#46;com.
            </li>
          </ol>
        </div>
      </div>
    </div>
    <desktopEnthusiastAd/>
  </div>
</template>

<script>
  import * as Util from './Util.js';
  import desktopEnthusiastAd from './desktopEnthusiastAd.vue';
  import * as AdUtil from './AdUtil.js';

  export default {
    components: { desktopEnthusiastAd },
    data() {
      return {
        showAd: true, showReporting: false
      };
    },
    mounted() {
      AdUtil.loadScript();
    },
    methods: {
      hideAd() {
        Util.trackEvent('Wheel', 'HideDesktopAd', '');
        this.showAd = false;
      },
      toggleReportingBox() {
        this.showReporting = !this.showReporting;
        Util.trackEvent('Wheel', 'ToggleBadAdInstructions', this.showReporting ? 'Show' : 'Hide');
      }
    }
  }
</script>

<style scoped>
  .ad-declaration {
    font-family: Quicksand;
    color: gray;
    margin-bottom: 16px;
  }
</style>