import Vue from 'vue';
import Vuex from 'vuex';
import wheelStore from './wheelStore.js';
import preferencesStore from './preferencesStore.js';
import userStore from './userStore.js';
import hardwareStore from './hardwareStore.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    wheel: wheelStore,
    preferences: preferencesStore,
    user: userStore,
    hardware: hardwareStore
  }
})
