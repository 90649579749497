var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.displayLoginDialog, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.displayLoginDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-folder-open" }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("opendialog.Open wheel")) + "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("opendialog.To open wheels")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "auth-container" } })
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: { active: _vm.displayWheelDialog, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.displayWheelDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c(
                "header",
                { staticClass: "modal-card-head" },
                [
                  _c("p", { staticClass: "modal-card-title" }, [
                    _c("i", { staticClass: "fa fa-folder-open" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("opendialog.Open wheel")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("profiledropdown", {
                    on: {
                      "log-out": function($event) {
                        return _vm.enter_inactive()
                      },
                      "start-wait-animation": function($event) {
                        return _vm.$emit("start-wait-animation")
                      },
                      "stop-wait-animation": function($event) {
                        return _vm.$emit("stop-wait-animation")
                      },
                      "show-snackbar-message": function(msg) {
                        return _vm.$emit("show-snackbar-message", msg)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c(
                  "table",
                  { staticClass: "table can-go-dark" },
                  [
                    _vm._l(_vm.savedWheels, function(wheel) {
                      return _c("tr", { key: wheel.config.title }, [
                        _c("td", [_vm._v(_vm._s(wheel.config.title))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { type: "is-light" },
                                on: {
                                  click: function($event) {
                                    return _vm.enter_openingWheel(wheel.path)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "far fa-folder-open" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("common.Open")) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.online
                          ? _c(
                              "td",
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { type: "is-light" },
                                    on: {
                                      click: function($event) {
                                        return _vm.enter_confirmingDelete(
                                          wheel.path,
                                          wheel.config.title
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-trash-alt"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.Delete")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    }),
                    _vm._v(" "),
                    _vm.noSavedWheels
                      ? _c("tr", [
                          _c("td", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("opendialog.No wheels found")) +
                                "\n            "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Close")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }