<template>
  <div class="card can-go-dark" style="margin-top:10px; border-radius:5px">
    <div class="card-content" style="padding:10px">
      <div class="content">
        <div v-if="appInfoVisible">
          <b-button @click="toggleVisibility" type="is-light" pack="fas"
            icon-left="chevron-circle-down" size="is-small" style="float:right; margin-top:-1px; border-radius:7px">
          </b-button>
          <span style="color:#999">
            {{ $t('appInfo.Version') }} {{ version }}
            <router-link to="/faq/changelog">
              <b-tag type="is-light is-rounded" rounded v-if="!userHasSeenCurrentVersion">
                New!
              </b-tag>
            </router-link>
          </span>
          <span style="float:right;margin-right:10px">
            <router-link to="/faq/changelog">
              Changelog
            </router-link>
          </span>
          <hr style="margin-top:0.5em; margin-bottom: 4px">
          <b-carousel v-model="shownSlide" :arrow="false" :pause-info="false" :indicator="false">
            <b-carousel-item style="height: 150px;">
              <section class="is-medium is-white">
                <div class="has-text-centered">
                  <div style="margin-bottom: 12px">
                    This is the old version of wheelofnames.com. Please tell us
                    why you prefer this version over the new one.
                  </div>
                  <div>
                    <b-button @click="openFeedbackForm" type="is-primary" pack="fa" icon-left="comment">{{ $t('toolbar.Feedback') }}</b-button>
                    <b-button type="is-primary" pack="fa" icon-left="arrow-left" onclick="location.href='https://wheelofnames.com'">Go back</b-button>
                  </div>
                </div>
              </section>
            </b-carousel-item>
          </b-carousel>
        </div>
        <div v-else>
          <b-button @click="toggleVisibility" type="is-light" pack="fas" icon-left="chevron-circle-up" size="is-small" style="float:right;margin-top: -1px; border-radius:7px"></b-button>
          <span style="color:#999">
            {{ $t('appInfo.Version') }} {{ version }}
            <router-link to="/faq/changelog">
              <b-tag type="is-light is-rounded" rounded v-if="!userHasSeenCurrentVersion">
                New!
              </b-tag>
            </router-link>
          </span>
          <span style="float:right;margin-right:10px">
            <router-link to="/faq/changelog">
              Changelog
            </router-link>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as Util from './Util.js';
  import * as ServerFunctions from './ServerFunctions.js';
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        votedInPoll: false,
        shownSlide: 0,
        carousels: []
      }
    },
    computed: {
      ...mapGetters([
        'appInfoVisible', 'version', 'userHasSeenCurrentVersion'
      ])
    },
    methods: {
      toggleVisibility() {
        Util.trackEvent('Wheel', 'ToggleAppInfoVisibility', '');
        this.$store.commit('toggleAppInfoVisibility');
      },
      submitPollResponse(pollTitle, response, carouselIndex) {
        Util.trackEvent('Wheel', `${pollTitle}Poll`, `${response}`);
        this.votedInPoll = true;
      },
      openFeedbackForm() {
        const url = Util.getFeedbackFormUrl(navigator.userAgent, this.version);
        window.open(url, '_new');
      }
    }
  }
</script>
