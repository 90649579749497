<template>
  <section class="section">
    <div class="columns">
      <div class="column">
        <whatIsItForCard/>
        &nbsp;
        <sustainabilityCard/>
      </div>
      <div class="column">
        <howToUseCard/>
        &nbsp;
        <statsCard/>
      </div>
      <div class="column">
        <isMyDataPrivateCard/>
        &nbsp;
        <canTheWheelBeRiggedCard/>
      </div>
    </div>
  </section>
</template>

<script>

  import howToUseCard from './howToUseCard.vue';
  import isMyDataPrivateCard from './isMyDataPrivateCard.vue';
  import whatIsItForCard from './whatIsItForCard.vue';
  import statsCard from './statsCard.vue';
  import canTheWheelBeRiggedCard from './canTheWheelBeRiggedCard.vue';
  import sustainabilityCard from './sustainabilityCard.vue';

  export default {
    components: {
      howToUseCard, isMyDataPrivateCard, whatIsItForCard, statsCard,
      canTheWheelBeRiggedCard, sustainabilityCard
    }
  }

</script>