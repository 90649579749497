export const timeago = (pastTime, currentTime) => {
  if (!pastTime) return '-';
  if (!currentTime) currentTime = Date.now();
  const seconds = Math.floor((currentTime - pastTime) / 1000);
  const intervals = [
    Math.floor(seconds / 31536000), Math.floor(seconds / 2592000),
    Math.floor(seconds / 604800),   Math.floor(seconds / 86400),
    Math.floor(seconds / 3600),     Math.floor(seconds / 60)
  ];
  const times = ['year', 'month', 'week', 'day', 'hour', 'minute'];
  for (const key in intervals) {
    if (intervals[key] > 1) {
      return intervals[key] + ' ' + times[key] + 's ago';
    }
    else if (intervals[key] === 1) {
      return intervals[key] + ' ' + times[key] + ' ago';
    }
  }
  return 'moments ago';
}

export const firestoremilliseconds = (timestamp) => {
  if (timestamp && timestamp._seconds) return timestamp._seconds * 1000;
  if (timestamp && timestamp.seconds) return timestamp.seconds * 1000;
}

export const dollaramount = (amount) => {
  if (isNumber(amount)) return '$ ' + amount.toFixed(2);
}

export const localestring = (value) => {
  if ((value || value==0) && value.toLocaleString) return value.toLocaleString();
}

export const percent = (value) => {
  return isNumber(value) && value>=0 ? Math.round(value*100) + ' %' : '?';
}

function isNumber(value) {
  return Number.isFinite(value);
}
