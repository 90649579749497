var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        active: _vm.displayTitleAndDescriptionDialog,
        width: 640,
        scroll: "keep"
      },
      on: {
        "update:active": function($event) {
          _vm.displayTitleAndDescriptionDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
        _c("header", { staticClass: "modal-card-head" }, [
          _c("p", { staticClass: "modal-card-title" }, [
            _c("i", { staticClass: "fas fa-edit" }),
            _vm._v(
              " " +
                _vm._s(_vm.$t("common.Edit title and description")) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "modal-card-body can-go-dark" },
          [
            _c(
              "b-field",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: _vm.$t("common.Wheel title") }
              },
              [
                _c("b-input", {
                  ref: "wheelTitleInput",
                  attrs: { maxlength: "50" },
                  nativeOn: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.enterPressed($event)
                    }
                  },
                  model: {
                    value: _vm.wheelTitle,
                    callback: function($$v) {
                      _vm.wheelTitle = $$v
                    },
                    expression: "wheelTitle"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-bottom": "0" },
                attrs: { label: _vm.$t("common.Wheel description") }
              },
              [
                _c("b-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "200",
                    "custom-class": "has-fixed-size"
                  },
                  nativeOn: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.enterPressed($event)
                    }
                  },
                  model: {
                    value: _vm.wheelDescription,
                    callback: function($$v) {
                      _vm.wheelDescription = $$v
                    },
                    expression: "wheelDescription"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass: "modal-card-foot",
            staticStyle: { "justify-content": "flex-end" }
          },
          [
            _c(
              "b-button",
              {
                attrs: { size: "is-medium" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("common.Cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: { size: "is-medium", type: "is-primary" },
                on: {
                  click: function($event) {
                    return _vm.setTitleAndDescription()
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(_vm.$t("common.OK")) + "\n      ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }