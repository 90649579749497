var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        "flex-grow": "1",
        display: "flex",
        "flex-direction": "column"
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            overflow: "auto",
            "border-radius": "10px",
            "border-color": "#dbdbdb",
            "border-style": "solid",
            "flex-grow": "1",
            height: "300px"
          }
        },
        [
          _vm._l(_vm.entries, function(entry, i) {
            return [
              _c("advancedSlice", {
                ref: "entry" + i,
                refInFor: true,
                attrs: { entry: entry, i: i }
              })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticStyle: { width: "100%", "margin-top": "8px" },
          attrs: { type: "is-primary", disabled: _vm.wheelIsBusy },
          on: {
            click: function($event) {
              return _vm.addEntry()
            }
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("advancededitor.Add entry")) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }