<template>
  <span style="color:#CCC">
    <b-tooltip type="is-light" multilined :label="title">
      <i class="fas fa-question-circle"></i>
    </b-tooltip>
  </span>
</template>

<script>
  export default {
    props: { title: String }
  }
</script>
