export async function userIsLoggedIn(auth) {
  const user = await getLoggedInUser(auth);
  return !!user && !user.isAnonymous;
}

export async function getLoggedInUser(auth) {
  if (auth.currentUser) {
    return auth.currentUser;
  }
  else {
    return new Promise(function(resolve) {
      auth.onAuthStateChanged(function(user) {
        resolve(user);
      })
    })
  }
}

export async function logIn(auth, provider) {
  provider.setCustomParameters({ prompt: 'select_account' });
  await auth.signInWithPopup(provider);
  return auth.currentUser;
}

export async function logInAnonymously(auth) {
  await auth.signInAnonymously()
  return auth.currentUser;
}

export async function logInToSheets(auth, provider) {
  provider.addScope('https://www.googleapis.com/auth/drive.readonly');
  provider.addScope('https://www.googleapis.com/auth/spreadsheets.readonly');
  provider.setCustomParameters({ prompt: 'select_account' });
  const result = await auth.signInWithPopup(provider);
  return result.credential.accessToken;
}
