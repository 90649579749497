var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", staticStyle: { "text-align": "center" } },
    [
      _c("canvas", {
        staticStyle: { width: "100%" },
        attrs: { id: "wheelCanvas", width: "700", height: "700" },
        on: {
          click: function($event) {
            return _vm.spin()
          }
        }
      }),
      _vm._v(" "),
      _vm.displayOverlayText
        ? _c("wheelOverlayText", {
            on: {
              click: function($event) {
                return _vm.spin()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }