var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.displayLoginDialog, width: 640, scroll: "keep" },
          on: {
            "update:active": function($event) {
              _vm.displayLoginDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c("header", { staticClass: "modal-card-head" }, [
                _c("p", { staticClass: "modal-card-title" }, [
                  _c("i", { staticClass: "fa fa-link" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("common.Link Google Spreadsheet")) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("sheetdialog.When you link a spreadsheet")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("sheetdialog.To import sheets")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticStyle: { "margin-top": "10px", color: "#999" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "common.If you dont see a Google login button below"
                          )
                        ) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticStyle: { height: "40px" },
                    attrs: {
                      type: "image",
                      alt: "Sign in with Google",
                      src: "/images/btn_google_signin_dark_normal_web@2x.png"
                    },
                    on: {
                      click: function($event) {
                        return _vm.enter_userIsLoggingIn()
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.displaySheetConfigDialog,
            width: 640,
            scroll: "keep"
          },
          on: {
            "update:active": function($event) {
              _vm.displaySheetConfigDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-card", staticStyle: { width: "auto" } },
            [
              _c(
                "header",
                { staticClass: "modal-card-head" },
                [
                  _c("p", { staticClass: "modal-card-title" }, [
                    _c("i", { staticClass: "fa fa-link" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("common.Link Google Spreadsheet")) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("profiledropdown", {
                    on: {
                      "log-out": function($event) {
                        return _vm.enter_inactive()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("section", { staticClass: "modal-card-body can-go-dark" }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("sheetdialog.Selected spreadsheet")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c("b-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.sheetTitle,
                          callback: function($$v) {
                            _vm.sheetTitle = $$v
                          },
                          expression: "sheetTitle"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-fifth" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("sheetdialog.Tab")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("sheetdialog.Select a tab"),
                            expanded: ""
                          },
                          model: {
                            value: _vm.selectedTab,
                            callback: function($$v) {
                              _vm.selectedTab = $$v
                            },
                            expression: "selectedTab"
                          }
                        },
                        _vm._l(_vm.tabs, function(tab) {
                          return _c(
                            "option",
                            { key: tab, domProps: { value: tab } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(tab) +
                                  "\n              "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-fifth" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("sheetdialog.Column")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-select",
                        {
                          attrs: {
                            loading: _vm.loadingColumns,
                            placeholder: _vm.$t("sheetdialog.Select a column"),
                            expanded: ""
                          },
                          model: {
                            value: _vm.selectedColumn,
                            callback: function($$v) {
                              _vm.selectedColumn = $$v
                            },
                            expression: "selectedColumn"
                          }
                        },
                        _vm._l(_vm.columns, function(col) {
                          return _c(
                            "option",
                            { key: col.id, domProps: { value: col.id } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(col.name) +
                                  "\n              "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-fifth" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("sheetdialog.First row")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      _c(
                        "b-checkbox",
                        {
                          model: {
                            value: _vm.firstRowIsHeader,
                            callback: function($$v) {
                              _vm.firstRowIsHeader = $$v
                            },
                            expression: "firstRowIsHeader"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "sheetdialog.Is a header and should not be imported"
                                )
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "footer",
                {
                  staticClass: "modal-card-foot",
                  staticStyle: { "justify-content": "flex-end" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "is-medium" },
                      on: {
                        click: function($event) {
                          return _vm.enter_inactive()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("common.Cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "is-medium",
                        disabled: !_vm.linkSheetButtonEnabled,
                        type: "is-primary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.enter_linkingSheet()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("sheetdialog.Link sheet")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }