<template>
  <div class="animated-background" ref="overlay">
    <div class="content">
      <p class="animated-text" style="color:#FFFFFF">
        {{ winnerText }}
      </p>
    </div>
  </div>
</template>

<script>
  import * as Util from './Util.js';

  export default {
    data() {
      return {winnerText: ''}
    },
    methods: {
      show(winningEntry) {
        if (winningEntry.text) {
          this.winnerText = winningEntry.text;
          this.$refs.overlay.style.display = 'block';
          setTimeout(() => {
            this.$refs.overlay.style.display = 'none';
          }, 6000);
        }
      }
    }
  }
</script>

<style scoped>
  .animated-background {
    text-align: center;
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 45;
    animation-name: fade-animation;
    animation-duration: 6s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
  }
  @keyframes fade-animation {
    0% {
      opacity: 0.7;
    }
    100% {
      opacity: 0;
    }
  }
  .content {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .animated-text {
    color: #FFFFFF;
    font-size: 200px;
    font-family: Quicksand;
    animation-name: zoom-animation;
    animation-duration: 6s;
    animation-timing-function: ease-in;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
  }
  @keyframes zoom-animation {
    0% {
      transform: scale(0.03);
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }
</style>
