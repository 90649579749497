export default class CircularCounter {

  constructor(startIndex, endIndex, length) {
    this.startIndex = startIndex;
    this.endIndex = endIndex;
    this.length = length;
    this.index = this.startIndex;
  }

  isDone() {
    return (this.index == this.endIndex);
  }

  getIndex() {
    return this.index;
  }

  increment() {
    this.index += 1;
    if (this.index >= this.length) {
      this.index -= this.length
    }
  }

}
